@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  position: relative;
}

::-webkit-scrollbar {
  width: 0;
}

.noScrollbar::-webkit-scrollbar {
  width: 0;
}